<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2023-12-20 09:44:19
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2023-12-22 15:30:48
 * @FilePath: \visions\src\views\zhyl\smartPublicGuard\healthTestRun\healthReport\tab2\tab2.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="tab">
    <div class="box">
      <div class="top">
        <div class="item item1">
          <span>数据分析</span>
        </div>
        <div class="item">
          <span>65岁老人查体基本情况详情</span>
        </div>
      </div>
      <div class="bottom">
        <div class="item item1">
          <div class="chart" id="echart"></div>
        </div>
        <div class="item">
          <div class="list">
            <div class="cell">
              <span>65岁-70岁老年人体检人数</span>
            </div>
            <div class="cell">
              <span>70岁-80岁老年人体检人数</span>
            </div>
            <div class="cell">
              <span>80岁以上老年人体检人数</span>
            </div>
          </div>
          <div class="list">
            <div class="cell">
              <span>合计</span>
            </div>
            <div class="cell">
              <span>1人</span>
            </div>
            <div class="cell">
              <span>合计</span>
            </div>
            <div class="cell">
              <span>1人</span>
            </div>
            <div class="cell">
              <span>合计</span>
            </div>
            <div class="cell">
              <span>1人</span>
            </div>
          </div>
          <div class="list">
            <div class="cell">
              <span>男</span>
            </div>
            <div class="cell">
              <span>1人</span>
            </div>
            <div class="cell">
              <span>男</span>
            </div>
            <div class="cell">
              <span>1人</span>
            </div>
            <div class="cell">
              <span>男</span>
            </div>
            <div class="cell">
              <span>1人</span>
            </div>
          </div>
          <div class="list">
            <div class="cell">
              <span>女</span>
            </div>
            <div class="cell">
              <span>1人</span>
            </div>
            <div class="cell">
              <span>女</span>
            </div>
            <div class="cell">
              <span>1人</span>
            </div>
            <div class="cell">
              <span>女</span>
            </div>
            <div class="cell">
              <span>1人</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {},
  data () {
    return {
      activeIndex: '0',
      showTest: false,
      showDetection: false,
      showTestDetection: false
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.$nextTick(() => {
      this.canvas()
    })
    window.addEventListener('resize', this.getresize)
  },
  methods: {
    getresize () {
      setTimeout(() => {
        this.myChart.resize()
      }, 500)
    },
    canvas () {
      if (!document.getElementById('echart')) return
      var chartDom = document.getElementById('echart')
      this.myChart = this.$echarts.init(chartDom)
      var app = {}
      const posList = [
        'left',
        'right',
        'top',
        'bottom',
        'inside',
        'insideTop',
        'insideLeft',
        'insideRight',
        'insideBottom',
        'insideTopLeft',
        'insideTopRight',
        'insideBottomLeft',
        'insideBottomRight'
      ]
      app.configParameters = {
        rotate: {
          min: -90,
          max: 90
        },
        align: {
          options: {
            left: 'left',
            center: 'center',
            right: 'right'
          }
        },
        verticalAlign: {
          options: {
            top: 'top',
            middle: 'middle',
            bottom: 'bottom'
          }
        },
        position: {
          options: posList.reduce(function (map, pos) {
            map[pos] = pos
            return map
          }, {})
        },
        distance: {
          min: 0,
          max: 100
        }
      }
      app.config = {
        rotate: 90,
        align: 'left',
        verticalAlign: 'middle',
        position: 'insideBottom',
        distance: 15,
        onChange: function () {
          const labelOption = {
            rotate: app.config.rotate,
            align: app.config.align,
            verticalAlign: app.config.verticalAlign,
            position: app.config.position,
            distance: app.config.distance
          }
          this.myChart.setOption({
            series: [
              {
                label: labelOption
              },
              {
                label: labelOption
              },
              {
                label: labelOption
              },
              {
                label: labelOption
              }
            ]
          })
        }
      }
      const labelOption = {
        show: true,
        position: app.config.position,
        distance: app.config.distance,
        align: app.config.align,
        verticalAlign: app.config.verticalAlign,
        rotate: app.config.rotate,
        formatter: '{c}  {name|{a}}',
        fontSize: 16,
        rich: {
          name: {}
        }
      }
      const option = {
        color: ['#006699', '#4CABCE', '#e94949'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          // y: '10%',
          data: ['合计体检人数', '男体检人数', '女体检人数'],
          textStyle: {
            fontSize: 16
          }
        },
        grid: {
          show: false,
          top: '10%',
          right: '10%',
          bottom: '10%',
          left: '10%'
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar', 'stack'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: ['65-70岁', '70-80岁', '80岁以上'],
            axisLabel: {
              show: true,
              color: '#000',
              fontSize: 16
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: true,
              lineStyle: {
                color: '#000'
              }
            },
            axisLabel: {
              show: true,
              color: '#000',
              fontSize: 14
            },
            splitLine: {
              lineStyle: {
                color: '#000'
              }
            }
          }
        ],
        series: [
          {
            name: '合计体检人数',
            type: 'bar',
            barGap: '20%',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [320, 332, 301]
          },
          {
            name: '男体检人数',
            type: 'bar',
            barGap: '20%',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [220, 182, 191]
          },
          {
            name: '女体检人数',
            type: 'bar',
            barGap: '20%',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [150, 232, 201]
          }
        ]
      }
      option && this.myChart.setOption(option)
    },
    handleSelect (key) {
      this.activeIndex = key
    },
    openTest () {
      this.showTest = true
    },
    closeTest () {
      this.showTest = false
    },
    openDetection () {
      this.showDetection = true
    },
    closeDetection () {
      this.showDetection = false
    },
    openTestDetection () {
      this.showTestDetection = true
    },
    closeTestDetection () {
      this.showTestDetection = false
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;

  .box {
    width: 100%;
    height: 100%;
    border-top: 1px solid #EBEEF5;
    border-left: 1px solid #EBEEF5;
    box-sizing: border-box;
    // border-collapse: collapse;

    .top {
      display: flex;
      width: 100%;
      height: 60px;

      .item {
        display: flex;
        align-items: center;
        width: 60%;
        height: 100%;
        padding-left: 20px;
        border-right: 1px solid #EBEEF5;
        border-bottom: 1px solid #EBEEF5;
        box-sizing: border-box;
        color: #000;
        font-size: 18px;
      }

      .item1 {
        width: 40%;
      }
    }

    .bottom {
      display: flex;
      width: 100%;
      height: calc(100% - 60px);

      .item {
        width: 60%;
        height: 100%;
        padding: 20px;
        border-right: 1px solid #EBEEF5;
        border-bottom: 1px solid #EBEEF5;
        box-sizing: border-box;
        color: #000;
        font-size: 18px;

        .chart {
          width: 100%;
          height: 100%;
        }

        .list {
          display: flex;
          width: 100%;
          margin: 10px 0;

          .cell {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            height: 60px;
            text-align: center;
            border: 1px solid #e8e8e8;
            box-sizing: border-box;
          }
        }
      }

      .item1 {
        width: 40%;
      }
    }
  }
}
</style>
